<template>
  <div class="box">
    <SATMenu v-if="false" active="1" />
    <div class="container">
      <div class="sat">
        <h1 class="sat_left">
          <p>Digital AP</p>
        </h1>
        <!-- <div class="change-version">
          <el-button
            v-show="isNew === 1"
            @click="changeVersion(0)"
            style="width:auto"
            ><i class="el-icon-refresh"></i>
            {{ $t("sat.switch_old") }}</el-button
          >
          <el-button
            v-show="isNew === 0"
            @click="changeVersion(1)"
            style="width:auto"
            ><i class="el-icon-refresh"></i>
            {{ $t("sat.switch_new") }}</el-button
          >
        </div> -->
      </div>
      <hr />
      <SATType :type="testType"></SATType>
      <hr />
      <el-form ref="form" @submit.native.prevent>
        <el-form-item v-if="false">
          <div class="d-flex">
            <div>
              <el-radio-group v-model="search.source">
                <el-radio-button label="0" value="1">
                  Official
                </el-radio-button>
                <el-radio-button label="1" value="1">
                  SATMocks
                </el-radio-button>
              </el-radio-group>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <el-input
            ref="filterInput"
            placeholder="Name"
            v-model="search.search"
            @keyup.enter.native="searchResult"
          >
            <el-button
              el-button
              slot="append"
              icon="el-icon-search"
              @click="searchResult"
            >
              Search
            </el-button>
          </el-input>
        </el-form-item>
      </el-form>
      <el-alert v-if="resultsTotal > -1" type="success" :closable="false">
        <span slot="title">
          Results: <b>{{ resultsTotal }}</b>
        </span>
      </el-alert>
      <div v-if="practices">
        <div class="text-center" style="margin:15px 0">
          <el-pagination
            background
            @current-change="setPage"
            :page-size="pageSize"
            :current-page="page"
            :total="resultsTotal"
            :pager-count="9"
            layout="prev, pager, next"
          >
          </el-pagination>
        </div>
        <el-table
          v-if="practices.length > 0"
          :data="practices"
          style="width: 100%"
          stripe
        >
          <el-table-column label="Type" width="60">
            <template slot-scope="scope">
              <el-tooltip
                v-if="practiceType === 'composePractices'"
                class="item"
                effect="dark"
                content="Linear Test"
                placement="top"
              >
                <el-tag type="success">
                  <i class="fas fa-arrow-right"></i>
                </el-tag>
              </el-tooltip>
              <el-tooltip
                v-if="practiceType === 'adaptivePractices'"
                class="item"
                effect="dark"
                content="Adaptive Test"
                placement="top"
              >
                <el-tag type="warning">
                  <i class="fas fa-code-branch"></i>
                </el-tag>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="Name" min-width="100">
            <template slot-scope="scope">
              <b>{{ scope.row.exam.title }}</b>
            </template>
          </el-table-column>
          <template v-if="practiceType === 'composePractices'">
            <el-table-column label="Subjects" width="100">
              <template slot-scope="scope">
                <b class="text-success">
                  {{ scope.row.practices.length }}
                </b>
              </template>
            </el-table-column>
            <el-table-column label="Questions" width="100">
              <template slot-scope="scope">
                <b class="text-success">
                  {{ getQuestionsCount(scope.row.practices) }}
                </b>
              </template>
            </el-table-column>
          </template>
          <el-table-column label="Source" width="100" v-if="false">
            <template slot-scope="scope">
              <b>SATMocks</b>
            </template>
          </el-table-column>
          <el-table-column label="Cost" width="150">
            <template slot-scope="scope">
              <template v-if="isPro || isAdmin">
                <b class="originCoin mr-2">
                  {{ scope.row.exam.coin_plans[0].coin }}
                  {{
                    scope.row.exam.coin_plans[0].coin > 1
                      ? $t("sat.Coins")
                      : $t("sat.Coin")
                  }}
                  <span class="removeLine"></span>
                </b>
                <el-tooltip
                  v-if="isAdmin"
                  class="item"
                  effect="dark"
                  content="Admin"
                  placement="top"
                >
                  <b class="text-success">
                    <i class="fas fa-building"></i>
                    Free
                  </b>
                </el-tooltip>
                <el-tooltip
                  v-else-if="isPro"
                  class="item"
                  effect="dark"
                  content="Pro"
                  placement="top"
                >
                  <b class="admin-Pro" style="color: orange;">
                    <i class="fas fa-crown"></i> Free
                  </b>
                </el-tooltip>
              </template>
              <b v-else>
                {{ scope.row.exam.coin_plans.coin[0] }}
                {{
                  scope.row.exam.coin_plans.coin[0] > 1
                    ? $t("sat.Coins")
                    : $t("sat.Coin")
                }}
              </b>
            </template>
          </el-table-column>
          <el-table-column
            width="130"
            label="Actions"
            fixed="right"
            align="center"
          >
            <template slot-scope="scope">
              <el-tooltip
                class="mr-2"
                effect="dark"
                content="Start a test"
                placement="top"
              >
                <router-link
                  :to="{
                    name: 'SATTestLockPage',
                    params: {
                      practiceType: practiceType,
                      id: scope.row.id
                    }
                  }"
                >
                  <el-button type="success" size="mini">
                    <i class="fas fa-play"></i>
                  </el-button>
                </router-link>
              </el-tooltip>
              <el-tooltip
                class="mr-2"
                effect="dark"
                content="Browse"
                placement="top"
                v-if="isAdmin || isTeacher"
              >
                <router-link
                  :to="{
                    name: routerLink,
                    query: { id: scope.row.exam.test_id }
                  }"
                >
                  <el-button type="success" size="mini">
                    <i class="fas fa-eye"></i>
                  </el-button>
                </router-link>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <div v-else>
          <el-empty :description="$t('courses.empty-text')"></el-empty>
        </div>
        <div class="text-center" style="margin:15px 0">
          <el-pagination
            background
            @current-change="setPage"
            :page-size="pageSize"
            :current-page="page"
            :total="resultsTotal"
            :pager-count="9"
            layout="prev, pager, next"
          >
          </el-pagination>
        </div>
        <el-alert
          type="info"
          class="mt-2 mb-4"
          show-icon
          :closable="false"
          style="text-align:left"
        >
          <span>
            Need more practices? Want a particular type of drill practices?
          </span>
          <a @click="goToFeedbackPage" style="cursor: pointer;">
            <b>Let us know </b>
          </a>
          <span>and we'll create them for you!</span>
        </el-alert>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import SATMenu from "@/views/satList/components/SATMenu.vue";
import PracticeCard from "@/views/satList/components/PracticeCard";
import SATType from "@/views/satList/components/SATType";
import SAT from "@/apis/sat.js";
import Common from "@/mixins/common.js";
import Theme from "@/common/theme";
import { instant } from "@ivy-way/material";
import role from "@/mixins/role.js";

export default {
  metaInfo() {
    return {
      title: "SAT Practices - " + this.CompanyName
    };
  },

  components: {
    SATMenu,
    SATType
  },

  mixins: [Common, role],

  props: [],
  data() {
    return {
      practices: null,
      resultsTotal: 0,
      pageSize: 0,
      search: {
        search: null,
        source: null
      }
    };
  },
  computed: {
    routerLink() {
      if (this.practiceType === "composePractices") {
        return "SatComposePracticeDetailAll";
      } else if (this.practiceType === "adaptivePractices") {
        return "SatAdaptiveDetailAll";
      } else {
        return "SatPracticeDetailAll";
      }
    },
    isPro() {
      return this.$store.getters["user/getIsPro"];
    },
    isAdmin() {
      let isAdmin = this.isRoleAdmin();
      return isAdmin;
    },
    isTeacher() {
      let isTeacher = this.isRoleTeacher();
      return isTeacher;
    },
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    },
    instant() {
      return instant;
    },
    isNew() {
      let isNew = 1;
      if (this.$route.query.isNew === undefined) {
        isNew = 1;
      } else {
        isNew = parseInt(this.$route.query.isNew);
      }
      return isNew;
    },
    ...mapState("user", ["token", "lang"]),
    isLogin() {
      return this.token !== "";
    },
    subjectId() {
      let subjectId = null;
      if (this.$route.query.subject_id) {
        subjectId = this.$route.query.subject_id;
      }
      return subjectId;
    },
    searchString() {
      return this.$route.query.search ? this.$route.query.search : null;
    },
    page() {
      let page = 1;
      if (this.$route.query.page === undefined) {
        page = 1;
      } else {
        page = this.$route.query.page;
      }
      return parseInt(page);
    },
    testType() {
      let testType = "Full Tests";
      if (this.$route.query.type) {
        testType = this.$route.query.type;
      }
      return testType;
    },
    practiceType() {
      if (this.testType === "Full Tests") {
        return "composePractices";
      } else if (this.testType === "Adaptive Tests") {
        return "adaptivePractices";
      } else {
        return "composePractices";
      }
    },
    theme() {
      return Theme.name;
    }
  },
  watch: {},

  async mounted() {
    let res = null;
    this.search.search = this.searchString;
    if (this.testType === "Full Tests") {
      res = await SAT.getComposePractices({
        keyword: this.search.search,
        is_new: this.isNew,
        page: this.page,
        is_public: 1
      });
      this.practices = res.compose_practice.data;
      this.resultsTotal = res.compose_practice.total;
      this.pageSize = res.compose_practice.per_page;
    }
    if (this.testType === "Adaptive Tests") {
      res = await SAT.getAdaptivePractices({
        keyWord: this.search.search,
        is_new: this.isNew,
        page: this.page,
        is_public: 1
      });
      this.practices = res.adaptive_practices.data;
      this.resultsTotal = res.adaptive_practices.total;
      this.pageSize = res.adaptive_practices.per_page;
    }
  },

  methods: {
    searchResult() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          ...this.search,
          page: 1
        }
      });
    },
    getQuestionsCount(practices) {
      let count = 0;
      if (practices && practices.length > 0) {
        practices.forEach(item => {
          count += item.exam.exam_questions_count;
        });
      }
      return count;
    },
    async goToFeedbackPage() {
      window.open(
        "mailto:satmocks@gmail.com?subject=Requesting Practice Tests",
        "_blank"
      );
    },
    changeVersion(val) {
      this.$router.replace({
        name: this.$route.name,
        query: {
          page: 1,
          isNew: val
        }
      });
    },
    setPage(page) {
      this.routerPush({
        name: this.$route.name,
        params: { ...this.$route.params },
        query: { ...this.$route.query, page: page }
      });
    }
  }
};
</script>

<style scoped>
::v-deep .el-table__header th,
::v-deep .el-table th.el-table__cell {
  background: #f5f7fa;
}
.originCoin {
  font-size: 16px;
  color: #aaa;
  position: relative;
  line-height: 0.8;
  font-weight: 700;
}
.removeLine {
  border-top: 2px solid #aaa;
  position: absolute;
  left: 0px;
  top: 12px;
  transform: rotate(-6deg);
  width: 50px;
}
.sat {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
}
.box .container {
  padding-top: 0;
}

.sat_left {
  margin: 20px 0;
  display: flex;
  color: var(--themeColor);
  text-decoration: none;
}

.sat_left > span {
  font-weight: 700;
  font-size: 20px;
}
.sat_left > p {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 0px;
}
.create-compose-practice {
  margin-left: 10px;
  font-size: 60%;
  line-height: 50px;
}
@media screen and (max-width: 768px) {
  .box .container {
    padding: 0 15px 20px 15px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .sat_left {
    margin: 0;
    margin-top: 10px;
  }
  .sat_left > p {
    font-weight: 700;
    font-size: 24px;
  }
}
</style>
